import React from "react";
import { connect } from 'react-redux';
import { Link, navigate } from "gatsby"
import i18n from '../../i18n/i18n';
import { getForm, sendAnswers } from '../../actions/DLSAction';
import { withStyles } from '@material-ui/core/styles';
import { FormGroup, FormControlLabel, Typography, RadioGroup, Radio, Button } from '@material-ui/core';
import { toast } from 'react-toastify';

const styles = theme => ({
    formContainer: {
        backgroundColor: '#D7F0CA',
        padding: '10px 20px'
    },
    typography: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '15px',
        fontSize: '20px'
    },
    radio: {
        padding: 10
    },
    buttons: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center'
    },
    button: {
        margin: 20
    }
});
class DlsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: {}
        }
    }
    componentDidMount() {
        this.props.getForm(this.props.location.state.id_upitnik);
    }

    changeFormControl = (question, e) => {
        var answers = { ...this.state.answers };
        answers[question] = e.target.value
        this.setState({ answers })
    }
    onSubmit = () => {
        const { form } = this.props;
        const { answers } = this.state;
        if (Object.keys(form).length > Object.keys(answers).length) {
            toast.error(i18n.t('You must answer all the questions!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        } else {
            this.props.sendAnswers(answers);
            navigate('/');
        }
    }

    render() {
        const { form, classes } = this.props;
        const { answers } = this.state;
        return (
            <div className={classes.formContainer} >
                {
                    form ? Object.keys(form).map((question, i) => {
                        return <FormGroup>
                            <Typography className={classes.typography}>{`${i + 1}. ${form[question][0].naziv}`}</Typography>
                            <RadioGroup
                                aria-label="position"
                                name="position"
                                className={classes.radio}
                                value={answers[question]}
                                onChange={(e) => this.changeFormControl(question, e)}
                            >
                                {
                                    form[question].map((offered, ind) =>
                                        <FormControlLabel
                                            value={`${offered.id_odgovor}`}
                                            control={<Radio color="primary" />}
                                            label={offered.odgovor}
                                            labelPlacement="left"
                                        />
                                    )
                                }
                            </RadioGroup>
                        </FormGroup>
                    }) :
                        <Typography className={classes.typography}>
                            You've already done the test! <Link to="/dls"> Go back </Link>
                        </Typography>
                }
                {form &&
                    <div className={classes.buttons}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={() => this.onSubmit()}>Submit</Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            onClick={() => navigate('/')}>Cancel</Button>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        form: state.dlsReducer.form
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getForm: (id) => dispatch(getForm(id)),
        sendAnswers: (answers) => dispatch(sendAnswers(answers))
    };
}
export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(DlsForm));
